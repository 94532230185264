@import url('https://fonts.googleapis.com/css?family=Montserrat|Quicksand&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #fdfdfd;
  color: #666;
  margin: 0 20px;
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, 
h1, h2, h3,
button {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; 
}


a:link,
a:visited {
    color: #ffbf80;
    text-decoration: none;
}

a:hover,
a:active {
  text-decoration: dotted;
}

p {
  margin: 0 0 12px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


button {
  background: #666;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 3px 10px;
}

.pageStyle{
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-size: 18px;
  letter-spacing: normal;
  margin: 0 auto 40px;
  max-width: 800px;
  min-width: 600px;
  text-align: left;
}

/* darkmode for individual image view */

body.darkmode {
  background: #333;
}

body.darkmode .nav a.active,
body.darkmode .nav a:hover,
body.darkmode .nav a:active {
  color: #ccc;
}